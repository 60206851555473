import API from "@/main";
import store from "./index.js";

const state = {
    paymentData: null,
};

const getters = {
    getBindedProviders(state) {
        let filteredProviders = {};

        Object.keys(state.paymentData ?? {}).forEach((key) => {
            let filteredType;

            filteredType = state.paymentData[key].filter((provider) => {
                return (
                    provider.type === "rocketwork" ||
                    provider.payment_data.id !== null
                );
            });

            if (filteredType.length > 0) {
                filteredProviders[key] = filteredType;
            }
        });

        return filteredProviders;
    },
};

const actions = {
    loadPaymentData({ commit }, data) {
        return new Promise((resolve, reject) => {
            API.get(`partners/v1/${data.partnerId}/payment-data`)
                .then((response) => {
                    commit("SET_PAYMENT_DATA", response.data.data);

                    resolve(response);
                })
                .catch((error) => {
                    reject(error);
                });
        });
    },

    storePaymentProvider(_, data) {
        return new Promise((resolve, reject) => {
            API.post(`partners/v1/${data.partnerId}/payment-data`, {
                payment_provider_id: data.payment_provider_id,
                data: data.data,
            })
                .then((response) => {
                    store.dispatch("createNotification", {
                        title: "Провайдер привязан",
                        type: "success",
                    });

                    resolve(response);
                })
                .catch((error) => {
                    if (error?.response?.status === 400) {
                        store.dispatch("createNotification", {
                            title: "Провайдер не привязан",
                            description: "Данные введены неверно",
                            type: "warning",
                        });
                    } else {
                        store.dispatch("createNotification", {
                            title: "Провайдер не привязан",
                            description: "Что-то пошло не так",
                            type: "warning",
                        });
                    }

                    reject(error);
                });
        });
    },

    deletePaymentProvider(_, data) {
        return new Promise((resolve, reject) => {
            API.delete(`partners/v1/${data.partnerId}/payment-data/${data.id}`)
                .then((response) => {
                    resolve(response);
                })
                .catch((error) => {
                    reject(error);
                });
        });
    },
};

const mutations = {
    SET_PAYMENT_DATA(state, payload) {
        state.paymentData = payload;
    },
};

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations,
};
