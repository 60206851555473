import API from "@/main.js";
import store from "./index";
import formatGetParams from "@/package/helpers/format-get-params.helpers";
import downloadResponse from "@/package/helpers/downloadResponse/download-response";

const state = {
    products: [],
    product: null,

    categories: [],

    statusTemplate: "",
    statusUploadProductsFileV2: "",
    // isLoadingProducts: true,
    statusUpdatingUploadedProducts: "",
    statusSavingUploadedProducts: "",
    statusRewritingUploadedProducts: "",

    uploadProgress: 0,

    uploadedProducts: [],
    controller: new AbortController(),

    statusExportingData: "",
};

const getters = {};

const actions = {
    loadProducts({ commit }, data) {
        return new Promise((resolve, reject) => {
            API.get(`/partners/v1/${data.partnerId}/products`, {
                params: {
                    search: data.search,
                    page: data.page,
                },
            })
                .then((response) => {
                    commit("SET_PRODUCTS", response.data.data);

                    resolve(response);
                })
                .catch((error) => {
                    reject(error);

                    store.dispatch("createNotification", {
                        title: "Что-то пошло не так",
                        description:
                            "Перезагрузите страницу или попробуйте позже",
                        type: "warning",
                    });
                });
        });
    },

    loadProduct({ commit }, data) {
        return new Promise((resolve, reject) => {
            API.get(`/partners/v1/${data.partnerId}/products/${data.productId}`)
                .then((response) => {
                    commit("SET_PRODUCT", response.data.data);

                    resolve(response);
                })
                .catch((error) => {
                    reject(error);

                    store.dispatch("createNotification", {
                        title: "Что-то пошло не так",
                        description:
                            "Перезагрузите страницу или попробуйте позже",
                        type: "warning",
                    });
                });
        });
    },

    createProduct(_, data) {
        return new Promise((resolve, reject) => {
            API.post(`/partners/v1/${data.partnerId}/products`, data.product, {
                headers: { "Accept-Language": "ru" },
            })
                .then((response) => {
                    resolve(response);

                    store.dispatch("createNotification", {
                        title: "Товар добавлен",
                        type: "success",
                    });
                })
                .catch((error) => {
                    reject(error);

                    let title = "Что-то пошло не так";
                    let description =
                        "Перезагрузите страницу или попробуйте позже";

                    if (error?.response?.status === 422) {
                        title = error.response.data.message;
                        description = "";
                    }

                    store.dispatch("createNotification", {
                        title: title,
                        description: description,
                        type: "warning",
                    });
                });
        });
    },

    updateProduct(_, data) {
        return new Promise((resolve, reject) => {
            API.put(
                `/partners/v1/${data.partnerId}/products/${data.productId}`,
                data.product,
                {
                    headers: { "Accept-Language": "ru" },
                },
            )
                .then((response) => {
                    resolve(response);

                    store.dispatch("createNotification", {
                        title: "Данные изменены",
                        type: "success",
                    });
                })
                .catch((error) => {
                    reject(error);

                    let title = "Что-то пошло не так";
                    let description =
                        "Перезагрузите страницу или попробуйте позже";

                    if (error?.response?.status === 422) {
                        title = error.response.data.message;
                        description = "";
                    }

                    store.dispatch("createNotification", {
                        title: title,
                        description: description,
                        type: "warning",
                    });
                });
        });
    },

    deleteProduct(_, data) {
        return new Promise((resolve, reject) => {
            API.put(
                `/partners/v1/${data.partnerId}/products/${data.productId}/soft-delete`,
            )
                .then((response) => {
                    resolve(response);

                    store.dispatch("createNotification", {
                        title: "Товар удален",
                        type: "success",
                    });
                })
                .catch((error) => {
                    reject(error);

                    store.dispatch("createNotification", {
                        title: "Что-то пошло не так",
                        description:
                            "Перезагрузите страницу или попробуйте позже",
                        type: "warning",
                    });
                });
        });
    },

    loadCategories({ commit }, data) {
        return new Promise((resolve, reject) => {
            API.get(`/partners/v1/${data.partnerId}/products/categories`)
                .then((response) => {
                    commit("SET_CATEGORIES", response.data.data);

                    resolve(response);
                })
                .catch((error) => {
                    reject(error);

                    store.dispatch("createNotification", {
                        title: "Что-то пошло не так",
                        description:
                            "Перезагрузите страницу или попробуйте позже",
                        type: "warning",
                    });
                });
        });
    },

    downloadTemplate(
        { commit },
        { partnerId, templateType = "product", fileType = "xlsx" },
    ) {
        commit("SET_STATUS_TEMPLATE", "DOWNLOADING");

        return new Promise((resolve, reject) => {
            API.post(
                `partners/v2/${partnerId}/uploads/${templateType}/template/${fileType}`,
                {},
                {
                    responseType: "blob",
                },
            )
                .then((response) => {
                    downloadResponse({
                        response: response.data,
                    });
                    commit("SET_STATUS_TEMPLATE", "SUCCESS");

                    resolve(response);
                })
                .catch((error) => {
                    commit("SET_STATUS_TEMPLATE", "ERROR");

                    reject(error);
                });
        });
    },

    uploadProductsFileV2(
        { commit, state },
        { partnerId, file, templateType = "product_rewrite", fileType = "csv" },
    ) {
        commit("SET_STATUS_UPLOAD_PRODUCTS_FILE_V2", "UPLOADING");

        let formData = new FormData();
        formData.append("file", file);

        return new Promise((resolve, reject) => {
            API.post(
                `partners/v2/${partnerId}/uploads/${templateType}/import/${fileType}`,
                formData,
                {
                    onUploadProgress: function (progressEvent) {
                        let percentCompleted = Math.round(
                            (progressEvent.loaded * 100) / progressEvent.total,
                        );

                        commit("SET_UPLOAD_PROGRESS", percentCompleted);
                    },
                    signal: state.controller.signal,
                },
            )
                .then((response) => {
                    resolve(response);
                })
                .catch((error) => {
                    reject(error);
                })
                .finally(() => {
                    commit("SET_STATUS_UPLOAD_PRODUCTS_FILE_V2", "SUCCESS");
                });
        });
    },

    cancelUploadProductsFile({ state }) {
        state.controller.abort();
    },

    loadUploadedProducts(
        { commit },
        { partnerId, templateType = "product", page },
    ) {
        return new Promise((resolve, reject) => {
            API.get(
                `partners/v2/${partnerId}/uploads/${templateType}/preview`,
                {
                    params: {
                        page: page,
                    },
                },
            )
                .then((response) => {
                    commit("SET_UPLOADED_PRODUCTS", response.data.data);

                    resolve(response);
                })
                .catch((error) => {
                    reject(error);
                });
        });
    },

    updateUploadedProduct(
        { commit },
        { partnerId, templateType = "product", rows },
    ) {
        commit("SET_STATUS_UPDATING_UPLOADED_PRODUCTS", "LOADING");

        return new Promise((resolve, reject) => {
            API.put(
                `partners/v2/${partnerId}/uploads/${templateType}/preview`,
                {
                    rows: rows,
                },
            )
                .then((response) => {
                    commit("SET_STATUS_UPDATING_UPLOADED_PRODUCTS", "SUCCESS");

                    resolve(response);
                })
                .catch((error) => {
                    commit("SET_STATUS_UPDATING_UPLOADED_PRODUCTS", "ERROR");

                    reject(error);
                });
        });
    },

    saveUploadedProducts({ commit }, { partnerId, templateType = "product" }) {
        commit("SET_STATUS_SAVING_UPLOADED_PRODUCTS", "LOADING");

        return new Promise((resolve, reject) => {
            API.post(
                `partners/v2/${partnerId}/uploads/${templateType}/preview/save`,
            )
                .then((response) => {
                    commit("SET_STATUS_SAVING_UPLOADED_PRODUCTS", "SUCCESS");

                    resolve(response);
                })
                .catch((error) => {
                    commit("SET_STATUS_SAVING_UPLOADED_PRODUCTS", "ERROR");

                    reject(error);
                });
        });
    },

    rewriteUploadedProducts(
        { commit },
        { partnerId, templateType = "product_rewrite" },
    ) {
        commit("SET_STATUS_REWRITING_UPLOADED_PRODUCTS", "LOADING");

        return new Promise((resolve, reject) => {
            API.post(
                `partners/v2/${partnerId}/uploads/${templateType}/preview/rewrite`,
            )
                .then((response) => {
                    commit("SET_STATUS_REWRITING_UPLOADED_PRODUCTS", "SUCCESS");

                    resolve(response);
                })
                .catch((error) => {
                    commit("SET_STATUS_REWRITING_UPLOADED_PRODUCTS", "ERROR");

                    reject(error);
                });
        });
    },

    deleteUploadedProduct(_, { partnerId, templateType = "product", rows }) {
        const params = formatGetParams({ rows: rows });

        return new Promise((resolve, reject) => {
            API.delete(
                `partners/v2/${partnerId}/uploads/${templateType}/preview?${params}`,
            )
                .then((response) => {
                    resolve(response);
                })
                .catch((error) => {
                    reject(error);
                });
        });
    },

    exportDataByTemplateType(
        { commit },
        {
            partnerId,
            templateType = "product",
            fileType = "xlsx",
            fileName = "register-products",
        },
    ) {
        commit("SET_STATUS_EXPORTING_DATA", "LOADING");

        return new Promise((resolve, reject) => {
            API.post(
                `partners/v2/${partnerId}/uploads/${templateType}/export/${fileType}`,
                {},
                {
                    responseType: "blob",
                },
            )
                .then((response) => {
                    downloadResponse({
                        response: response.data,
                        fileName: fileName,
                        fileType: fileType,
                    });

                    resolve(response);
                })
                .catch((error) => {
                    reject(error);
                })
                .finally(() => {
                    commit("SET_STATUS_EXPORTING_DATA", "SUCCESS");
                });
        });
    },
};

const mutations = {
    SET_PRODUCTS(state, products) {
        state.products = products;
    },

    SET_PRODUCT(state, product) {
        state.product = product;
    },

    SET_CATEGORIES(state, payload) {
        state.categories = payload;
    },

    SET_STATUS_TEMPLATE(state, payload) {
        state.statusTemplate = payload;
    },

    SET_STATUS_UPLOAD_PRODUCTS_FILE_V2(state, payload) {
        state.statusUploadProductsFileV2 = payload;
    },

    SET_UPLOAD_PROGRESS(state, payload) {
        state.uploadProgress = payload;
    },

    SET_UPLOADED_PRODUCTS(state, payload) {
        state.uploadedProducts = payload;
    },

    SET_STATUS_UPDATING_UPLOADED_PRODUCTS(state, payload) {
        state.statusUpdatingUploadedProducts = payload;
    },

    SET_STATUS_SAVING_UPLOADED_PRODUCTS(state, payload) {
        state.statusSavingUploadedProducts = payload;
    },

    SET_STATUS_REWRITING_UPLOADED_PRODUCTS(state, payload) {
        state.statusRewritingUploadedProducts = payload;
    },

    SET_STATUS_EXPORTING_DATA(state, payload) {
        state.statusExportingData = payload;
    },
};

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations,
};
