import API from "@/main";
import formatGetParams from "@/package/helpers/format-get-params.helpers";

const state = {
    selfCollections: [],
};

const getters = {};

const actions = {
    loadSelfCollections({ commit }, payload) {
        const params = formatGetParams(payload.params ?? {});

        return new Promise((resolve, reject) => {
            API.get(
                `partners/v1/${payload.partnerId}/self-collections?${params}`,
            )
                .then((response) => {
                    commit("SET_SELF_COLLECTIONS", response.data.data);

                    resolve(response);
                })
                .catch((error) => {
                    reject(error);
                });
        });
    },
};

const mutations = {
    SET_SELF_COLLECTIONS(state, payload) {
        state.selfCollections = payload;
    },
};

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations,
};
