import { SORTED_ANALYTIC_BLOCKS } from "@/package/const/sorted-analytic-blocks";

function formatBlock(type, value) {
    switch (type) {
        case "total":
            return {
                id: type,
                name: "Общая сумма выручки",
                amount: value,
                icon: "icon-money",
            };

        case "cash":
            return {
                id: type,
                name: "Оплата наличными",
                amount: value.sum,
                count: value.count,
                icon: "icon-wallet",
            };

        case "acquiring":
            return {
                id: type,
                name: "Безналичная оплата",
                amount: value.sum,
                count: value.count,
                icon: "icon-card",
            };

        case "average":
            return {
                id: type,
                name: "Средний чек",
                amount: value,
                icon: "icon-rating",
            };

        case "count":
            return {
                id: type,
                name: "Количество чеков",
                amount: value,
                icon: "icon-receipt",
            };

        case "nfc":
            return {
                id: type,
                name: "Бесконтактная оплата",
                amount: value.sum,
                count: value.count,
                icon: "icon-connection",
            };
    }
}

export default function formatBlocks(blocks) {
    let formattedBlocks = [];

    SORTED_ANALYTIC_BLOCKS.forEach((key) => {
        formattedBlocks.push(formatBlock(key, blocks[key]));
    });

    return formattedBlocks;
}
