<script>
import { MENU_ROUTES } from "@/package/const/menu-routes";
import { mapState, mapGetters } from "vuex";

export default {
    data() {
        return {
            MENU_ROUTES: MENU_ROUTES,
        };
    },

    computed: {
        ...mapState({
            accessList: (state) => state.auth.accessList,
        }),

        ...mapGetters({
            checkPermissions: "checkPermissions",
        }),

        filteredRoutes() {
            let filteredRoutes;

            filteredRoutes = this.MENU_ROUTES.filter((route) => {
                return this.checkPermissions(route.permissions);
            });

            // if (!this.checkPartnerAccess(this.accessList)) {
            //   filteredRoutes = filteredRoutes.filter((route) => {
            //     return route.name !== "Касса";
            //   });
            // }

            return filteredRoutes;
        },
    },

    methods: {
        setDefaultRoute(currentRoute, routes) {
            if (currentRoute.path === "/" && routes.length > 0) {
                this.$router.push(routes[0].path);
            }
        },

        checkPartnerAccess(partnerAccessList) {
            if (
                !partnerAccessList?.partner ||
                partnerAccessList?.partner.length === 0
            ) {
                return false;
            }

            if (partnerAccessList.partner[0].payment_data.length === 0) {
                return false;
            }

            if (
                partnerAccessList.partner[0].payment_data.every(
                    (paymentData) => {
                        return paymentData.is_active === false;
                    },
                )
            ) {
                return false;
            }

            return true;
        },
    },

    mounted() {
        this.setDefaultRoute(this.$route, this.filteredRoutes);
    },
};
</script>

<template>
    <div class="menu-routes">
        <router-link
            v-for="route in filteredRoutes"
            :key="route.id"
            :to="route.path"
            class="menu-routes__route"
            active-class="menu-routes__route_active"
        >
            <span :class="`icon-${route.icon}`"></span>
            {{ route.name }}
        </router-link>
    </div>
</template>

<style lang="scss">
.menu-routes {
    display: flex;
    flex-direction: column;

    &__route {
        padding: 16px;
        text-decoration: none;
        @include body-1;
        color: $dark-fifth;
        display: flex;
        align-items: center;

        span {
            display: block;
            width: 24px;
            height: 24px;
            background-color: $dark-fifth;
            margin-right: 8px;
        }

        &_active {
            background-color: $light-third;
            border-radius: 10px;
            @include body-1-bold;
            color: $dark-primary;

            span {
                background-color: $dark-primary;
            }
        }
    }
}
</style>
