import API from "@/main";

const state = {
    clientIntegration: null,
    clientIntegrationStatus: "",
};

const getters = {};

const actions = {
    loadClientIntegration({ commit }, { partnerId }) {
        commit("SET_CLIENT_INTEGRATION_STATUS", "LOADING");

        return new Promise((resolve, reject) => {
            API.get(`partners/v1/${partnerId}/client`)
                .then((response) => {
                    commit("SET_CLIENT_INTEGRATION", response.data.data);
                    commit("SET_CLIENT_INTEGRATION_STATUS", "SUCCESS");

                    resolve(response);
                })
                .catch((error) => {
                    reject(error);
                });
        });
    },

    renewSecretKey({ commit }, { partnerId }) {
        return new Promise((resolve, reject) => {
            API.post(`partners/v1/${partnerId}/client/renew`)
                .then((response) => {
                    commit("SET_CLIENT_INTEGRATION", response.data.data);

                    resolve(response);
                })
                .catch((error) => {
                    reject(error);
                });
        });
    },
};

const mutations = {
    SET_CLIENT_INTEGRATION(state, payload) {
        state.clientIntegration = payload;
    },

    SET_CLIENT_INTEGRATION_STATUS(state, payload) {
        state.clientIntegrationStatus = payload;
    },
};

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations,
};
