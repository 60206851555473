<script>
export default {
    emits: ["click"],

    props: {
        type: {
            type: String,
            default: "icon",
        },

        disabled: {
            type: Boolean,
            default: false,
        },
    },
};
</script>

<template>
    <button
        class="main-button-icon"
        :class="{ 'main-button-icon_hovered': type === 'icon' }"
        :disabled="disabled"
        type="button"
        @click.stop="$emit('click')"
    >
        <slot></slot>
    </button>
</template>

<style lang="scss" scoped>
.main-button-icon {
    border: none;
    background-color: transparent;
    padding: 0;
    margin: 0;
    cursor: pointer;
    display: flex;

    &_hovered {
        &:hover {
            background-color: $light-second;
            border-radius: 8px;

            :slotted(span) {
                background-color: $dark-primary !important;
            }
        }
    }

    &:disabled {
        pointer-events: none;
    }
}
</style>
