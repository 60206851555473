import API from "@/main.js";
import store from "./index";

const state = {
    user: null,
    permissions: null,

    accessList: null,

    owner: null,
};

const getters = {};

const actions = {
    auth() {
        return new Promise((resolve, reject) => {
            API.get("/sanctum/csrf-cookie")
                .then((response) => {
                    resolve(response);
                })
                .catch((error) => {
                    reject(error);
                });
        });
    },

    loginUser(_, data) {
        return new Promise((resolve, reject) => {
            API.post("/auth/login", {
                username: data.username,
                password: data.password,
            })
                .then((response) => {
                    resolve(response);
                })
                .catch((error) => {
                    reject(error);
                });
        });
    },

    loadAccessList({ commit }) {
        return new Promise((resolve, reject) => {
            API.get("/auth/access-list")
                .then((response) => {
                    commit("SET_ACCESS_LIST", response.data.access_list);

                    resolve(response);
                })
                .catch((error) => {
                    reject(error);
                });
        });
    },

    checkUser({ commit }, id) {
        return new Promise((resolve, reject) => {
            API.get(`partners/v1/${id}/profile/check`)
                .then((response) => {
                    commit("SET_USER", response.data);
                    commit(
                        "SET_PERMISSION",
                        response.data.current_access.permissions,
                    );

                    resolve(response);
                })
                .catch((error) => {
                    reject(error);
                });
        });
    },

    logout() {
        return new Promise((resolve, reject) => {
            API.get("auth/logout")
                .then((response) => {
                    resolve(response);
                })
                .catch((error) => {
                    reject(error);
                });
        });
    },

    resetPassword(_, user) {
        return new Promise((resolve, reject) => {
            API.post(`auth/${user}/password/reset`)
                .then((response) => {
                    store.dispatch("createNotification", {
                        title: "Данные изменены",
                        type: "success",
                    });

                    resolve(response);
                })
                .catch((error) => {
                    reject(error);
                });
        });
    },

    loadTSPOwner({ commit }, id) {
        return new Promise((resolve, reject) => {
            API.get(`partners/v1/${id}/profile/owner`)
                .then((response) => {
                    commit("SET_TSP_OWNER", response.data.data);

                    resolve(response);
                })
                .catch((error) => {
                    reject(error);
                });
        });
    },
};

const mutations = {
    SET_USER(state, payload) {
        state.user = payload;
    },

    SET_PERMISSION(state, permissions) {
        state.permissions = permissions;
    },

    SET_ACCESS_LIST(state, accessList) {
        state.accessList = accessList;
    },

    SET_TSP_OWNER(state, payload) {
        state.owner = payload;
    },
};

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations,
};
