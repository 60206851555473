<script>
export default {
    emits: ["close"],

    props: {
        noticeData: {
            type: Object,
            required: true,
        },

        autoClose: {
            type: Boolean,
            default: false,
        },
    },

    mounted() {
        if (this.autoClose) {
            setTimeout(() => {
                this.$emit("close");
            }, 6000);
        }
    },
};
</script>

<template>
    <div
        class="main-notice"
        :class="{
            'main-notice_warning': noticeData.type === 'warning',
            'main-notice_auto-close': autoClose,
        }"
    >
        <span
            class="main-notice__icon"
            :class="{
                'icon-done': noticeData.type === 'success',
                'icon-warning': noticeData.type === 'warning',
            }"
        />

        <div class="main-notice__content content">
            <h4 class="content__title">
                {{ noticeData.title }}
            </h4>

            <p v-if="noticeData.description" class="content__description">
                {{ noticeData.description }}
            </p>
        </div>
    </div>
</template>

<style scoped lang="scss">
.main-notice {
    width: 500px;
    padding: 24px;
    background-color: $light-primary;
    border-radius: 16px;
    position: fixed;
    bottom: -110%;
    left: 0;
    right: 0;
    margin: 0 auto;
    box-shadow: 0 0 20px rgba(0, 0, 0, 0.1);
    z-index: 100099;
    display: flex;
    flex-direction: row;
    gap: 16px;
    align-items: center;
    animation: show-notice 0.4s ease-in-out;
    animation-delay: 0.05s;
    animation-fill-mode: forwards;

    &_warning {
        width: 540px;
    }

    &_auto-close {
        animation: show-and-hide-notice 5s ease-in-out;
        animation-delay: 0.05s;
    }

    &__icon {
        display: inline-block;
        width: 48px;
        height: 48px;
    }

    .icon-done {
        background: $green;
    }

    .icon-warning {
        background: $red;
    }

    .content {
        width: calc(100% - 32px);
        display: flex;
        flex-direction: column;
        gap: 4px;

        &__title {
            width: calc(100% - 32px);
            @include text-1;
            color: $dark-primary;
        }

        &__description {
            width: calc(100% - 24px);
            @include body-1;
            color: $dark-sixth;
            word-wrap: break-word;
        }
    }
}

@media (max-width: 768px) {
    .main-notice {
        margin: 0 32px;
        width: inherit;
        animation: show-notice-mobile 0.4s ease-in-out;
        animation-delay: 0.05s;
        animation-fill-mode: forwards;

        &_auto-close {
            animation: show-and-hide-notice-mobile 5s ease-in-out;
            animation-delay: 0.05s;
        }
    }
}

@media (max-width: 500px) {
    .main-notice {
        margin: 0 16px;
    }
}

@media (max-width: 424px) {
    .main-notice {
        margin: 0 16px;
        padding: 16px;
    }
}

@keyframes show-notice {
    0% {
        bottom: -110%;
    }

    100% {
        bottom: 40px;
    }
}

@keyframes show-and-hide-notice {
    0% {
        bottom: -110%;
    }

    25% {
        bottom: 40px;
    }

    75% {
        bottom: 40px;
    }

    100% {
        bottom: -110%;
    }
}

@keyframes show-notice-mobile {
    0% {
        bottom: -110%;
    }

    100% {
        bottom: 24px;
    }
}

@keyframes show-and-hide-notice-mobile {
    0% {
        bottom: -110%;
    }

    25% {
        bottom: 24px;
    }

    75% {
        bottom: 24px;
    }

    100% {
        bottom: -110%;
    }
}
</style>
