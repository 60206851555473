import API from "@/main.js";
import store from "./index";

const state = {
    persons: [],
    statusPersons: "",
    person: null,

    roles: [],
};

const getters = {};

const actions = {
    loadPersons({ commit }, data) {
        commit("SET_STATUS_PERSONS", "LOADING");

        return new Promise((resolve, reject) => {
            API.get(`/partners/v1/${data.partnerId}/access`, {
                params: {
                    search: data.search,
                    page: data.page,
                },
            })
                .then((response) => {
                    commit("SET_PERSONS", response.data.data);
                    commit("SET_STATUS_PERSONS", "SUCCESS");

                    resolve(response);
                })
                .catch((error) => {
                    reject(error);
                    commit("SET_STATUS_PERSONS", "ERROR");

                    store.dispatch("createNotification", {
                        title: "Что-то пошло не так",
                        description:
                            "Перезагрузите страницу или попробуйте позже",
                        type: "warning",
                    });
                });
        });
    },

    loadPerson({ commit }, data) {
        return new Promise((resolve, reject) => {
            API.get(`/partners/v1/${data.partnerId}/access/${data.personId}`)
                .then((response) => {
                    commit("SET_PERSON", response.data.data);

                    resolve(response);
                })
                .catch((error) => {
                    reject(error);

                    store.dispatch("createNotification", {
                        title: "Что-то пошло не так",
                        description:
                            "Перезагрузите страницу или попробуйте позже",
                        type: "warning",
                    });
                });
        });
    },

    storePerson(_, data) {
        return new Promise((resolve, reject) => {
            API.post(
                `/partners/v1/${data.partnerId}/access`,
                {
                    first_name: data.firstName,
                    last_name: data.lastName,
                    middle_name: data.middleName,
                    email: data.email,
                    roles: [data.role.name],
                    password: data.password,
                },
                {
                    headers: { "Accept-Language": "ru" },
                },
            )
                .then((response) => {
                    resolve(response);

                    store.dispatch("createNotification", {
                        title: "Сотрудник добавлен",
                        type: "success",
                    });
                })
                .catch((error) => {
                    reject(error);

                    let title = "Что-то пошло не так";
                    let description =
                        "Перезагрузите страницу или попробуйте позже";

                    if (error?.response?.status === 422) {
                        title = error.response.data.message;
                        description = "";
                    }

                    store.dispatch("createNotification", {
                        title: title,
                        description: description,
                        type: "warning",
                    });
                });
        });
    },

    updatePerson(_, payload) {
        return new Promise((resolve, reject) => {
            API.put(
                `partners/v1/${payload.partnerId}/access/${payload.personId}`,
                payload.data,
                {
                    headers: { "Accept-Language": "ru" },
                },
            )
                .then((response) => {
                    resolve(response);

                    store.dispatch("createNotification", {
                        title: "Данные изменены",
                        type: "success",
                    });
                })
                .catch((error) => {
                    reject(error);

                    let title = "Что-то пошло не так";
                    let description =
                        "Перезагрузите страницу или попробуйте позже";

                    if (error?.response?.status === 422) {
                        title = error.response.data.message;
                        description = "";
                    }

                    store.dispatch("createNotification", {
                        title: title,
                        description: description,
                        type: "warning",
                    });
                });
        });
    },

    deletePerson(_, data) {
        return new Promise((resolve, reject) => {
            API.delete(`/partners/v1/${data.partnerId}/access/${data.personId}`)
                .then((response) => {
                    resolve(response);

                    store.dispatch("createNotification", {
                        title: "Сотрудник удален",
                        type: "success",
                    });
                })
                .catch((error) => {
                    reject(error);

                    store.dispatch("createNotification", {
                        title: "Что-то пошло не так",
                        description:
                            "Перезагрузите страницу или попробуйте позже",
                        type: "warning",
                    });
                });
        });
    },

    loadRolesPermissions({ commit }) {
        return new Promise((resolve, reject) => {
            API.get("/auth/context/role-permissions/list", {
                params: {
                    context: "partner",
                },
            })
                .then((response) => {
                    commit("SET_ROLES", response.data.data.roles);

                    resolve(response);
                })
                .catch((error) => {
                    reject(error);

                    store.dispatch("createNotification", {
                        title: "Что-то пошло не так",
                        description:
                            "Перезагрузите страницу или попробуйте позже",
                        type: "warning",
                    });
                });
        });
    },
};

const mutations = {
    SET_PERSONS(state, staffList) {
        state.persons = staffList;
    },

    SET_STATUS_PERSONS(state, payload) {
        state.statusPersons = payload;
    },

    SET_PERSON(state, payload) {
        state.person = payload;
    },

    SET_ROLES(state, payload) {
        state.roles = payload;
    },
};

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations,
};
