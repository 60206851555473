<script>
import { mapState, mapActions } from "vuex";
import ServiceSelector from "../ServiceSelector.vue";
import MenuRoutes from "@/components/menu/MenuRoutes";
import MainButtonIcon from "@/components/helpers/MainButtonIcon";
import MainButtonRedirect from "@/components/helpers/MainButtonRedirect.vue";

export default {
    components: {
        MainButtonIcon,
        MenuRoutes,
        ServiceSelector,
        MainButtonRedirect,
    },

    emits: ["close"],

    watch: {
        $route() {
            this.$emit("close");
        },
    },

    computed: {
        ...mapState({
            user: (state) => state.auth.user,
        }),

        formattedName() {
            return `${this.user.data.first_name} ${this.user.data.last_name}`;
        },
    },

    methods: {
        ...mapActions({
            logout: "auth/logout",
        }),

        logoutRequest() {
            this.logout().then(() => {
                window.location = "/login";
            });
        },
    },
};
</script>

<template>
    <div class="menu-component">
        <div class="menu-component__button-redirect">
            <MainButtonRedirect :text="'Назад'" @click="$emit('close')" />
        </div>

        <div class="menu-component__service-selector">
            <ServiceSelector />
        </div>

        <div class="menu-component__container">
            <div class="menu-component__profile profile">
                <div class="profile__wrapper">
                    <span>Вы вошли как</span>

                    <p>{{ formattedName }}</p>
                </div>

                <MainButtonIcon
                    class="profile__button-exit"
                    :type="'button'"
                    @click="logoutRequest"
                >
                    <span class="profile__icon-exit icon-exit"></span>
                </MainButtonIcon>
            </div>

            <menu-routes />

            <div class="menu-component__contacts contacts">
                <a
                    href="/documents/Механика_работы_RC_POS.pdf"
                    target="_blank"
                    class="contacts__email"
                >
                    <span class="icon-blank" /> Механика работы RC POS
                </a>

                <p class="contacts__title">Техническая поддержка</p>
                <a class="contacts__email" href="mailto:help@rcpos.app">
                    <span class="icon-mail"></span>help@rcpos.app
                </a>
                <a class="contacts__phone" href="tel:88005005762">
                    <span class="icon-phone"></span>8 800 500-57-62
                </a>
            </div>
        </div>
    </div>
</template>

<style lang="scss" scoped>
.menu-component {
    overflow: auto;
    max-height: 100vh;

    &__button-redirect {
        display: none;
        padding: 24px 16px 16px;
    }

    &__service-selector {
        padding: 24px 24px 0;
    }

    &__container {
        padding: 40px 40px 24px;
        display: flex;
        flex-direction: column;
        height: calc(100vh - 102px);
    }

    &__button {
        display: none;
        margin-left: auto;
    }

    &__icon {
        display: block;
        width: 32px;
        height: 32px;
        background-color: $dark-primary;
    }

    .profile {
        margin-bottom: 40px;
        display: flex;
        align-items: center;
        justify-content: space-between;

        &__wrapper {
            span {
                display: block;
                @include text-2;
                color: $dark-fifth;
                margin-bottom: 4px;
            }

            p {
                @include body-1-bold;
                color: $dark-primary;
            }
        }

        &__icon-exit {
            display: block;
            width: 32px;
            height: 32px;
            background-color: $dark-sixth;
        }

        &__button-exit {
            &:hover {
                .profile__icon-exit {
                    background-color: $dark-primary;
                }
            }
        }
    }

    .contacts {
        margin-top: auto;
        display: flex;
        flex-direction: column;
        border-top: 1px solid $outline-light;
        padding-top: 24px;
        padding-bottom: 16px;

        &__title {
            margin-bottom: 8px;
            @include text-2;
            color: $dark-fifth;
        }

        &__email {
            margin-bottom: 6px;
            color: $dark-primary;
            text-decoration: none;
            @include body-1;
            display: flex;
            align-items: center;
            gap: 8px;

            &:hover {
                color: $blue;

                .icon-mail {
                    background-color: $blue;
                }

                .icon-blank {
                    background-color: $blue;
                }
            }
        }

        &__phone {
            @include body-1;
            color: $dark-primary;
            text-decoration: none;
            display: flex;
            align-items: center;
            gap: 8px;

            &:hover {
                color: $blue;

                .icon-phone {
                    background-color: $blue;
                }
            }
        }

        .icon-mail {
            height: 24px;
            width: 24px;
            background-color: $dark-primary;
        }

        .icon-phone {
            height: 24px;
            width: 24px;
            background-color: $dark-primary;
        }

        .icon-blank {
            height: 24px;
            width: 24px;
            background-color: $dark-primary;
        }
    }
}

@media (max-width: 1024px) {
    .menu-component {
        &__button-redirect {
            display: block;
        }

        &__service-selector {
            padding: 0;
        }

        &__container {
            padding: 24px 16px;
            height: calc(100vh - 137px);
        }

        &__button {
            display: block;
        }

        .profile {
            margin-bottom: 24px;
        }

        .contacts {
            &__email,
            &__phone {
                color: $blue;
            }

            .icon-mail,
            .icon-blank,
            .icon-phone {
                background-color: $blue;
            }
        }
    }
}

@media (max-width: 425px) {
    .menu-component {
        .contacts {
            padding-bottom: 0;
        }
    }
}
</style>
