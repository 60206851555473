import API from "@/main.js";
import groupLogs from "@/package/usecases/logs/groupLogs/group-logs";

const state = {
    logs: [],
};

const getters = {
    getGroupedLogs(state) {
        return groupLogs(state.logs);
    },
};

const actions = {
    loadLogs({ commit }, payload) {
        return new Promise((resolve, reject) => {
            API.get(`partners/v1/${payload.partnerId}/activities`, {
                params: {
                    page: payload.page,
                    accesses: payload.accesses,
                    events: payload.events,
                    subjects: payload.subjects,
                },
            })
                .then((response) => {
                    if (payload.page === 1) {
                        commit("SET_LOGS", response.data.data);
                    } else {
                        commit("ADD_LOGS", response.data.data);
                    }

                    resolve(response);
                })
                .catch((error) => {
                    reject(error);
                });
        });
    },
};

const mutations = {
    SET_LOGS(state, payload) {
        state.logs = payload;
    },

    ADD_LOGS(state, payload) {
        state.logs = state.logs.concat(payload);
    },
};

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations,
};
