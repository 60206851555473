<script>
import vClickOutside from "click-outside-vue3";
import MainButtonWrapper from "@/package/components/MainButtonWrapper.vue";

export default {
    directives: {
        clickOutside: vClickOutside.directive,
    },

    components: { MainButtonWrapper },

    data() {
        return {
            isExpanded: false,
        };
    },

    methods: {
        hideItems() {
            this.isExpanded = false;
        },

        redirect() {
            window.location.replace(
                `${process.env.VUE_APP_FRONTEND_CP}cabinet/analytics`,
            );
        },
    },
};
</script>

<template>
    <div v-click-outside="hideItems" class="service-selector">
        <MainButtonWrapper
            class="service-selector__preview"
            :class="{ 'service-selector__preview_expanded': isExpanded }"
            @click="isExpanded = !isExpanded"
        >
            <div class="service-selector__image">
                <img src="../assets/logo_small.svg" alt="" />
            </div>
            <div class="service-selector__name">
                <p>RC POS</p>
                <span>Переключить сервис</span>
            </div>
            <div class="service-selector__icon">
                <span class="icon-arrow-down" />
            </div>
        </MainButtonWrapper>

        <div v-if="isExpanded" class="service-selector__list">
            <MainButtonWrapper
                class="service-selector__item item"
                @click="redirect"
            >
                <div class="item__image">
                    <img src="../assets/logo_cp.svg" alt="" />
                </div>
                <div class="item__name">
                    <p>RC CITY</p>
                </div>
            </MainButtonWrapper>
        </div>
    </div>
</template>

<style lang="scss" scoped>
.service-selector {
    position: relative;

    &__preview {
        padding: 16px;
        border-radius: 16px;
        gap: 16px;
        background-color: $light-primary;
        display: flex;
        align-items: center;
        width: 100%;
        cursor: pointer;

        &:hover,
        &:focus {
            background-color: $light-third;

            .service-selector {
                &__icon {
                    background-color: $light-fifth;
                }
            }
        }

        &_expanded {
            .service-selector {
                &__icon {
                    > .icon-arrow-down {
                        transform: rotate(180deg);
                    }
                }
            }
        }
    }

    &__image {
        min-width: 40px;
        width: 40px;
        height: 40px;
        display: flex;
        align-items: center;
        justify-content: center;

        > img {
            width: 100%;
        }
    }

    &__name {
        width: 100%;

        > p {
            @include text-1;
            font-weight: 600;
            color: $dark-primary;
            margin-bottom: 2px;
            line-height: 120%;
        }

        > span {
            @include text-2;
            color: $dark-fifth;
        }
    }

    &__icon {
        min-width: 32px;
        width: 32px;
        height: 32px;
        background-color: $light-third;
        border-radius: 8px;

        > .icon-arrow-down {
            width: 32px;
            height: 32px;
            background-color: $dark-primary;
        }
    }

    &__list {
        position: absolute;
        top: calc(100% + 8px);
        width: 100%;
        z-index: 999;
        padding: 8px;
        border-radius: 16px;
        background-color: $light-primary;
        box-shadow: 0 0 25px 0 rgba(33, 33, 33, 0.1);
    }

    .item {
        display: flex;
        align-items: center;
        gap: 12px;
        padding: 16px;
        border-radius: 12px;
        cursor: pointer;
        width: 100%;

        &__name {
            > p {
                @include body-1;
                color: $dark-primary;
            }
        }

        &__image {
            min-width: 40px;
            width: 40px;
            height: 40px;
            display: flex;
            align-items: center;
            justify-content: center;

            img {
                max-width: 40px;
                max-height: 40px;
            }
        }

        &:hover {
            background-color: $light-third;
        }
    }
}

@media (max-width: 1023px) {
    .service-selector {
        &__preview {
            border-radius: 0;
        }

        &__list {
            width: calc(100% - 16px);
            left: 8px;
            padding: 0;
        }
    }
}
</style>
