<script>
export default {
    emits: ["click"],

    props: {
        type: {
            type: String,
            default: "button",
        },
    },
};
</script>

<template>
    <button class="button-wrapper" :type="type" @click="$emit('click')">
        <slot />
    </button>
</template>

<style scoped>
.button-wrapper {
    background: none;
    border: none;
    width: auto;
    height: auto;
    cursor: pointer;
    color: inherit;
    text-align: left;
    padding: 0;
}
</style>
