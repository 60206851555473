export const MENU_ROUTES = [
    {
        id: 5,
        path: "/analytics",
        name: "Аналитика",
        icon: "statistic",
        permissions: ["partner", "partner.analytics"],
    },
    {
        id: 1,
        path: "/products",
        name: "Товары",
        icon: "dashboard",
        permissions: ["partner", "partner.products", "partner.products.view"],
    },
    {
        id: 2,
        path: "/staff",
        name: "Сотрудники",
        icon: "people",
        permissions: ["partner", "partner.access", "partner.access.view"],
    },
    {
        id: 3,
        path: "/cash-register",
        name: "Касса",
        icon: "wallet",
        permissions: ["partner.orders", "partner.orders.view"],
    },
    {
        id: 4,
        path: "/integration",
        name: "Интеграции",
        icon: "integration",
        permissions: ["partner"],
    },
    {
        id: 5,
        path: "/logs",
        name: "Лента активности",
        icon: "list",
        permissions: ["partner", "partner.activity", "partner.activity.view"],
    },
];
