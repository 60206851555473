import API from "@/main.js";
import store from "./index.js";
import formatGetParams from "@/package/helpers/format-get-params.helpers.js";

const state = {
    receipts: [],
    receipt: null,
    receiptStatus: "",

    receiptTransactions: [],
    receiptTransactionsStatus: "",

    statistic: null,

    refunds: null,
    refundsStatus: "",
};

const getters = {};

const actions = {
    loadReceipts({ commit }, payload) {
        return new Promise((resolve, reject) => {
            API.get(`partners/v1/${payload.partnerId}/orders`, {
                params: {
                    page: payload.page,
                    search: payload.search,
                },
            })
                .then((response) => {
                    commit("SET_RECEIPTS", response.data.data);

                    resolve(response);
                })
                .catch((error) => {
                    reject(error);

                    store.dispatch("createNotification", {
                        title: "Что-то пошло не так",
                        description:
                            "Перезагрузите страницу или попробуйте позже",
                        type: "warning",
                    });
                });
        });
    },

    loadReceipt({ commit }, payload) {
        commit("SET_RECEIPT_STATUS", "LOADING");

        return new Promise((resolve, reject) => {
            API.get(
                `partners/v1/${payload.partnerId}/orders/${payload.receiptId}`,
            )
                .then((response) => {
                    commit("SET_RECEIPT", response.data.data);
                    commit("SET_RECEIPT_STATUS", "SUCCESS");

                    resolve(response);
                })
                .catch((error) => {
                    commit("SET_RECEIPT_STATUS", "ERROR");
                    store.dispatch("createNotification", {
                        title: "Что-то пошло не так",
                        description:
                            "Перезагрузите страницу или попробуйте позже",
                        type: "warning",
                    });

                    reject(error);
                });
        });
    },

    loadReceiptTransactions({ commit }, payload) {
        commit("SET_RECEIPT_TRANSACTIONS_STATUS", "LOADING");

        return new Promise((resolve, reject) => {
            API.get(
                `partners/v1/${payload.partnerId}/orders/${payload.receiptId}/transactions`,
            )
                .then((response) => {
                    commit("SET_RECEIPT_TRANSACTIONS", response.data.data);
                    commit("SET_RECEIPT_TRANSACTIONS_STATUS", "SUCCESS");

                    resolve(response);
                })
                .catch((error) => {
                    commit("SET_RECEIPT_TRANSACTIONS_STATUS", "ERROR");

                    reject(error);
                });
        });
    },

    syncReceipts(_, payload) {
        return new Promise((resolve, reject) => {
            API.post(`partners/v1/${payload.partnerId}/orders/sync`)
                .then((response) => {
                    resolve(response);
                })
                .catch((error) => {
                    reject(error);

                    store.dispatch("createNotification", {
                        title: "Что-то пошло не так",
                        description:
                            "Перезагрузите страницу или попробуйте позже",
                        type: "warning",
                    });
                });
        });
    },

    loadStatisticByDates({ commit }, payload) {
        return new Promise((resolve, reject) => {
            API.get(`partners/v1/${payload.partnerId}/orders/statistics`, {
                params: {
                    date_start: payload.date_start,
                    date_end: payload.date_end,
                },
            })
                .then((response) => {
                    commit("SET_STATISTIC", response.data);

                    resolve(response);
                })
                .catch((error) => {
                    reject(error);

                    store.dispatch("createNotification", {
                        title: "Что-то пошло не так",
                        description:
                            "Перезагрузите страницу или попробуйте позже",
                        type: "warning",
                    });
                });
        });
    },

    loadRefunds({ commit }, payload) {
        commit("SET_REFUNDS_STATUS", "LOADING");

        const params = formatGetParams(payload.params ?? {});

        return new Promise((resolve, reject) => {
            API.get(`partners/v1/${payload.partnerId}/orders/refunds?${params}`)
                .then((response) => {
                    commit("SET_REFUNDS", response.data.data);
                    commit("SET_REFUNDS_STATUS", "SUCCESS");

                    resolve(response);
                })
                .catch((error) => {
                    commit("SET_REFUNDS_STATUS", "ERROR");

                    reject(error);
                });
        });
    },

    refundReceipt(_, payload) {
        return new Promise((resolve, reject) => {
            API.post(
                `partners/v1/${payload.partnerId}/orders/${payload.receiptId}/refund`,
            )
                .then((response) => {
                    resolve(response);
                })
                .catch((error) => {
                    reject(error);
                });
        });
    },
};

const mutations = {
    SET_RECEIPTS(state, payload) {
        state.receipts = payload;
    },

    SET_RECEIPT(state, payload) {
        state.receipt = payload;
    },

    SET_RECEIPT_TRANSACTIONS(state, payload) {
        state.receiptTransactions = payload;
    },

    SET_RECEIPT_TRANSACTIONS_STATUS(state, payload) {
        state.receiptTransactionsStatus = payload;
    },

    SET_RECEIPT_STATUS(state, payload) {
        state.receiptStatus = payload;
    },

    SET_STATISTIC(state, payload) {
        state.statistic = payload;
    },

    SET_REFUNDS(state, payload) {
        state.refunds = payload;
    },

    SET_REFUNDS_STATUS(state, payload) {
        state.refundsStatus = payload;
    },
};

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations,
};
