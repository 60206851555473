import { createI18n } from "vue-i18n";
import ru from "@/i18n/locales/ru.json";
import en from "@/i18n/locales/en.json";

const messages = {
    ru: ru,
    en: en,
};

const i18n = createI18n({
    locale: "ru",
    fallbackLocale: "ru",
    messages,
});

export default i18n;
