import { createRouter, createWebHistory } from "vue-router";
import store from "@/store";
import MainLayout from "@/components/layouts/MainLayout";
import RouteLayout from "@/components/layouts/RouteLayout";

const routes = [
    {
        path: "/",
        component: MainLayout,
        children: [
            {
                path: "products",
                component: RouteLayout,
                children: [
                    {
                        path: "",
                        name: "Products",
                        component: () =>
                            import("@/views/products/ProductsView"),
                        meta: {
                            title: "Товары",
                        },
                    },
                    {
                        path: "create",
                        name: "ProductsCreate",
                        component: () =>
                            import("@/views/products/ProductsCreate"),
                    },
                    {
                        path: ":id/edit",
                        name: "ProductsEdit",
                        component: () =>
                            import("@/views/products/ProductsEdit"),
                    },
                    {
                        path: "preview",
                        name: "ProductsPreview",
                        component: () =>
                            import("@/views/products/ProductsPreview.vue"),
                    },
                ],
            },
            {
                path: "staff",
                component: () => import("@/components/layouts/RouteLayout"),
                children: [
                    {
                        path: "",
                        name: "Staff",
                        component: () => import("@/views/staff/StaffView"),
                        meta: {
                            title: "Сотрудники",
                        },
                    },
                    {
                        path: "create",
                        name: "StaffCreate",
                        component: () => import("@/views/staff/StaffCreate"),
                    },
                    {
                        path: ":id/edit",
                        name: "StaffEdit",
                        component: () => import("@/views/staff/StaffEdit"),
                    },
                ],
            },
            {
                path: "/cash-register",
                component: () => import("@/components/layouts/RouteLayout"),
                children: [
                    {
                        path: "",
                        name: "CashRegister",
                        component: () =>
                            import("@/views/cash-register/CashRegister"),
                    },
                    {
                        path: "receipt/:id",
                        name: "Receipt",
                        component: () =>
                            import("@/views/cash-register/ReceiptView"),
                    },
                    {
                        path: "self-collection",
                        name: "SelfCollection",
                        component: () =>
                            import("@/views/cash-register/SelfCollection"),
                    },
                    {
                        path: "refunds",
                        name: "RefundsHistoryView",
                        component: () =>
                            import("@/views/cash-register/RefundsHistoryView"),
                    },
                ],
            },
            {
                path: "/integration",
                name: "Integrations",
                component: () =>
                    import("@/views/integration/IntegrationView.vue"),
            },
            {
                path: "/logs",
                name: "Logs",
                component: () => import("@/views/logs/LogsView"),
                meta: {
                    title: "Лента активности",
                },
            },
            {
                path: "/analytics",
                name: "Analytics",
                component: () => import("@/views/analytics/AnalyticsView.vue"),
                meta: {
                    title: "Аналитика",
                },
            },
            {
                path: "/payment-data",
                name: "PaymentData",
                component: () => import("@/views/payment-data/PaymentData.vue"),
            },
        ],
    },
    {
        path: "/login",
        component: () => import("../components/layouts/AuthLayout"),
        children: [
            {
                path: "",
                name: "Authorization",
                component: () => import("../components/auth/AuthForm"),
            },
        ],
    },
];

const router = createRouter({
    history: createWebHistory(process.env.BASE_URL),
    routes,
});

router.beforeEach((to, from, next) => {
    let user = store.state.auth.user;

    if (user === null) {
        store.dispatch("auth/auth").then(() => {
            store
                .dispatch("auth/loadAccessList")
                .then((response) => {
                    let id = response.data.access_list.partner[0].context_id;
                    store
                        .dispatch(`auth/checkUser`, id)
                        .then(() => {
                            if (to.path === "/login") {
                                next("/");
                            } else {
                                next();
                            }
                        })
                        .catch(() => {
                            if (to.path !== "/login") {
                                next("/login");
                            } else {
                                next();
                            }
                        });
                })
                .catch(() => {
                    if (to.path !== "/login") {
                        next("/login");
                    } else {
                        next();
                    }
                });
        });
    } else {
        next();
    }
});

export default router;
