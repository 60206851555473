import API from "@/main";
import formatBlocks from "@/package/usecases/analytics/formatBlocks/format-blocks";

const state = {
    analyticsTypes: null,
    graphs: null,
};

const getters = {
    getFormattedBlocks(state) {
        if (state.analyticsTypes !== null) {
            return formatBlocks(state.analyticsTypes);
        } else {
            return [];
        }
    },
};

const actions = {
    loadAnalyticsTypes({ commit }, payload) {
        return new Promise((resolve, reject) => {
            API.get(`partners/v1/${payload.partnerId}/analytics/types`, {
                params: payload.params,
            })
                .then((response) => {
                    commit("SET_ANALYTICS_TYPES", response.data.data);

                    resolve(response);
                })
                .catch((error) => {
                    reject(error);
                });
        });
    },

    buildGraphsByType({ commit }, payload) {
        commit("SET_GRAPHS", null);
        return new Promise((resolve, reject) => {
            API.get(
                `partners/v1/${payload.partnerId}/analytics/graph/${payload.type}`,
                {
                    params: payload.params,
                },
            )
                .then((response) => {
                    commit("SET_GRAPHS", response.data.data);

                    resolve(response);
                })
                .catch((error) => {
                    reject(error);
                });
        });
    },
};

const mutations = {
    SET_ANALYTICS_TYPES(state, payload) {
        state.analyticsTypes = payload;
    },

    SET_GRAPHS(state, payload) {
        state.graphs = payload;
    },
};

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations,
};
