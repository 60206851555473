export default function downloadResponse({
    response,
    fileName = "template",
    fileType = "xlsx",
}) {
    const file = new Blob([response]);
    const fileURL = URL.createObjectURL(file);
    const link = document.createElement("a");

    link.setAttribute("href", fileURL);
    link.setAttribute(
        "download",
        `${fileName}.${fileType === "xlsx_extended" ? "xlsx" : fileType}`,
    );
    link.style.display = "none";

    document.body.appendChild(link);

    link.click();

    document.body.removeChild(link);
}
