<script>
export default {
    props: {
        text: {
            type: String,
            required: true,
        },

        arrowPosition: {
            type: String,
            default: "left",
        },

        buttonStyle: {
            type: String,
            default: "transparent",
        },
    },
};
</script>

<template>
    <button
        class="main-button-redirect"
        :class="[
            { 'main-button-redirect_reversed': arrowPosition === 'right' },
            `main-button-redirect_${buttonStyle}`,
        ]"
        @click="$emit('click')"
    >
        <span class="main-button-redirect__icon icon-arrow-left"></span>
        <span class="main-button-redirect__text">{{ text }}</span>
    </button>
</template>

<style lang="scss" scoped>
.main-button-redirect {
    background: transparent;
    border: none;
    display: flex;
    align-items: center;
    cursor: pointer;
    padding: 0;
    text-align: start;

    &__text {
        transition: margin 0.08s linear;
        margin: 0 0 0 8px;
        @include body-1;
        color: $dark-sixth;
    }

    &:hover,
    &:focus {
        .main-button-redirect {
            &__text {
                margin: 0 0 0 12px;
            }
        }
    }

    &:active {
        .main-button-redirect {
            &__text {
                margin: 0 0 0 8px;
            }
        }
    }

    &__icon {
        min-width: 24px;
        height: 24px;
        display: inline-block;
        background: $dark-sixth;
    }

    &_reversed {
        flex-direction: row-reverse;

        .main-button-redirect {
            &__icon {
                transform: rotate(180deg);
            }

            &__text {
                margin: 0 8px 0 0;
            }
        }

        &:hover,
        &:focus {
            .main-button-redirect {
                &__text {
                    margin: 0 12px 0 0;
                }
            }
        }

        &:active {
            .main-button-redirect {
                &__text {
                    margin: 0 8px 0 0;
                }
            }
        }
    }
}
</style>
