import { createStore } from "vuex";
import auth from "./auth.store.js";
import staff from "./staff.store.js";
import product from "./product.store";
import receipts from "./receipts.store";
import logs from "./logs.store";
import analytics from "./analytics.store";
import selfCollection from "./self-collection.store";
import paymentData from "./payment-data.store";
import integration from "./integration.store";

export default createStore({
    state: {
        notification: null,
        innerWidth: window.innerWidth,
    },

    getters: {
        checkPermissions: () => (permissions) => {
            return permissions.some((permission) => {
                return auth.state.permissions.includes(permission);
            });
        },
    },

    mutations: {
        SET_NOTIFICATION(state, data) {
            state.notification = data;
        },

        SET_INNER_WIDTH(state, data) {
            state.innerWidth = data;
        },
    },

    actions: {
        createNotification({ commit, state }, data) {
            if (state.notification) {
                commit("SET_NOTIFICATION", null);

                setTimeout(() => {
                    commit("SET_NOTIFICATION", data);
                }, 50);
            } else {
                commit("SET_NOTIFICATION", data);
            }
        },
    },

    modules: {
        auth,
        staff,
        product,
        receipts,
        logs,
        analytics,
        selfCollection,
        paymentData,
        integration,
    },
});
